import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../Component/Layout/Layout';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import "./Dashboard.css";
import plus from "../../Assets/Images/icon/plus.png";
import avatar from "../../Assets/Images/icon/blank-profile.jpg";
import addMemberIcon from "../../Assets/Images/icon/add-member.png";
import createAssignIcon from "../../Assets/Images/icon/create-assignment.png";
import moreInvoiceIcon from "../../Assets/Images/icon/more-invoice.svg";
import invoiceAvatar from "../../Assets/Images/icon/invoice-avatar.png";
import bookAssetIcon from "../../Assets/Images/icon/book-asset.png";
import calenderIcon from "../../Assets/Images/icon/calendar-check-01.svg"
import ticketIcon from "../../Assets/Images/icon/ticket.png";
import walletIcon from "../../Assets/Images/icon/wallet.svg";
import upcomingBookingAvatar from "../../Assets/Images/icon/upcoming-bookin-dash.png";
import messageDotIcon from "../../Assets/Images/icon/message-dots-circle.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import onlineShow from "../../Assets/Images/icon/online.svg";
import { CHATTER, USER } from '../../CommonFunction/types';
import { enhanceGroupUserName, enhanceToGroupUserName, formatResourceDate, messageFormatDate } from "../../CommonFunction/Function";
import { getUsers } from '../../api/admin';
import { getChatters } from '../../api/message';
import spaceAvatar from "../../Assets/Images/icon/spaceAvatar.png";
import { Link, useNavigate } from 'react-router-dom';
import { DESKIE_API as API } from '../../config';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { adminResourceList } from '../../api/resource';
import { getInvoicesList } from '../../api/invoice';

interface MSG {
  sender: string,
  recipient: string,
  message: string,
  type: boolean,
  mode: boolean,
  file_path: string,
  created_at: string,
}

const events = [
  { title: 'Conference Room - Picasso', date: '2024-03-07T10:30:00' },
  { title: 'Conference Room - Picasso', date: '2024-03-10T14:00:00' },
  { title: 'Conference Room - Picasso', date: '2024-03-11T11:00:00' },
];

const Dashboard = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);
  const [result, setResult] = useState<string[]>([]);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [limitValue, setLimitValue] = useState<number>(0);
  const pageCount = Math.ceil(totalValue / limitValue);
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [pageValue, setPageValue] = useState<number>();
  const [bookingResult, setBookingResult] = useState<any[]>([]);
  const [invoiceList, setInvoiceList] = useState<any[]>([]);

  useEffect(() => {
    adminResourceList().then((data) => {
      setBookingResult(data)
    }).catch((err) => {console.log(err)});
  
    getInvoicesList(5, 1, "unpaid").then((data) => {
      if (data && Array.isArray(data.invoices)) {
        setInvoiceList(data.invoices);
        // setTotalValue(data.total)
        // setLimitValue(data.limit)
        // setPageValue(data.page)
      }
    }).catch((err) => {
      console.log(err);
    })
  }, [limit, page]);


  const nextPage = () => {
    setResult([])
    setPage(page + 1)
    setNextButton(true)
  }

  const prevPage = () => {
    setResult([])
    setPage(page - 1)
  }

  return (
    <>
      <Layout>
        <div className='mainContent'>
          <Container className='mb-4'>
            <Row>
              <Col md={8}>
                <div className="dashboardStatus">
                  <div className="card-grid">
                    <div className="card-item">
                      <img src={addMemberIcon} alt="Add Member" />
                      <Link to="/member" className='card-title'>Add Member</Link>
                      <div className='plus-wrapper'>
                        <img src={plus} width='20px' height='20px' alt="Plus Icon" />
                      </div>
                    </div>
                    <div className="card-item">
                      <img src={createAssignIcon} alt="Create Assignment" />
                      <Link to="/assets" className='card-title'>Create Assignment</Link>
                      <div className='plus-wrapper'>
                        <img src={plus} width='20px' height='20px' alt="Plus Icon" />
                      </div>
                    </div>
                    <div className="card-item">
                      <img src={bookAssetIcon} alt="Book an Asset" />
                      <Link to="/resources" className='card-title'>Book an Asset</Link>
                      <div className='plus-wrapper'>
                        <img src={plus} width='20px' height='20px' alt="Plus Icon" />
                      </div>
                    </div>
                    <div className="card-item">
                      <img src={ticketIcon} alt="Submit a Ticket" />
                      <p className='card-title'>Submit a Ticket</p>
                      <div className='plus-wrapper'>
                        <img src={plus} width='20px' height='20px' alt="Plus Icon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboardStatus mt-4">
                  <div className="statusHeading">
                    <img src={walletIcon} alt="bookings" width='24px' height='24px' />
                    <p>Pending Invoices</p>
                  </div>
                  <div className='invoice-divider' />
                  {invoiceList && invoiceList.map((invoice: any, i: number) => <div className='invoice-card'>
                    <div className='invoice-header' style={{ justifyContent: 'space-between', width: '100%' }}>
                      <p className='invoice-title'>#{invoice.invoice_id}</p>
                      <div className='invoice-header'>
                        <p className='invoice-price'>${invoice.amount} <span> /mo</span></p>
                        <div className='vertical-divider' />
                        <Button className='invoice-button' variant='outline-primary'><img src={moreInvoiceIcon} alt="spaces" /></Button>
                      </div>
                    </div>
                    <div className='invoice-divider' style={{ margin: 0 }} />
                    <table style={{ width: '100%' }}>
                      <thead className='invoice-subHeading'>
                        <tr>
                          <th colSpan={4}>Assignment</th>
                          <th colSpan={1}>Due Date</th>
                          <th colSpan={1}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr >
                          <td colSpan={4}>
                            <div className='invoice-name-flex invoiceSpacesList'>
                              {invoice.space_images.split(',').map((image: string, index: number) =>
                                image === 'imgNull' ?
                                  <img key={index} src={spaceAvatar} width="32px" height="32px" alt="avatar" style={{ borderRadius: "50%" }} />
                                  :
                                  <img key={index} src={`${API}/${image}`} width="32px" height="32px" alt="avatar" style={{ borderRadius: "50%" }} />
                              )}
                              {/* <p className='invoice-name'>Mailbox #23</p> */}
                            </div>
                          </td>
                          <td colSpan={1}><p className='invoice-date'>April 1, 2024</p></td>
                          <td colSpan={1}>
                            <div className='invoice-status'>
                              <p>Unpaid</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>)}
                </div>
              </Col>
              <Col md={4}>
                <div className="dashboardStatus">
                  <div className="statusHeading">
                    <img src={calenderIcon} alt="bookings" width='24px' height='24px' />
                    <p>Upcoming Bookings</p>
                  </div>
                  <div className='weeklyCalender'>

                    <FullCalendar
                      plugins={[dayGridPlugin]}
                      initialView="dayGridWeek"
                      headerToolbar={{
                        left: 'title',
                        center: '',
                        right: '',
                      }}// Hides the header toolbar
                      titleFormat={{ year: 'numeric', month: 'long' }}
                      stickyFooterScrollbar
                      dayHeaderContent={({ date }) => {
                        const dayNumber = new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(date);
                        return (
                          <div className="custom-day-cell">
                            <div className="day-name">
                              {new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date)}
                            </div>
                            <div className="day-number">
                              {dayNumber}
                            </div>
                          </div>
                        )
                      }}
                      height="auto"
                      contentHeight="auto"
                      events={events}
                    />
                  </div>

                  {bookingResult.map((resource) => (
                    <div className='upcoming-booking-card'>
                      <img src={`${API}/${resource.resource_image}`} alt="resource" />
                      <div>
                        <p className='upcoming-booking-card-title'>{resource.resource_name}</p>
                        <p className='upcoming-booking-card-date'>{formatResourceDate(resource.book_date)}, {resource.start_time} - {resource.end_time}</p>                    </div>
                    </div>
                  ))}
                </div>
                <div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  )
}

export default Dashboard