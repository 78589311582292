import React, { useEffect, useState } from 'react';
import ConfirmationModal from '../../Component/ConfirmationModal/ConfirmationModal';
import ReactQuill from 'react-quill';
import { getTourAgreement, tourAgreement } from '../../api/agreement';
import { v4 as uuidv4 } from 'uuid';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';

interface AccountProps {
  settingTab: (type: string) => void
}

const Tours = ({ settingTab }: AccountProps) => {
  const [agreementId, setAgreementId] = useState('')
  const [confirmationShow, setConfirmationShow] = useState(false)
  const [content, setContent] = useState('');
  const [agreement, setAgreement] = useState(false);

  useEffect(() => {
    getTourAgreement().then((data) => {
      if (data.data.tour_email) {
        setContent(data.data.tour_email)
        setAgreement(true);
      }
      setAgreementId(data.data.id)
    }).catch((err) => { console.log(err) });
  }, [])

  var modules: any = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { align: [] },
      ],
      [
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            'custom-color',
          ],
        },
      ],
    ],
  }

  var formats: any = [
    'header',
    'height',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'color',
    'bullet',
    'indent',
    'link',
    'align',
    'size',
  ]
  const handleProcedureContentChange = (content: string) => {
    setContent(content)
  }

  const agreementClick = () => {
    setAgreement(!agreement)
  }

  const tourAdd = () => {
    let agreementInfo = {
      id: agreementId ? agreementId : uuidv4(),
      tour_email: content
    }
    tourAgreement(agreementInfo).then((data) => {
      showNotifications(TOAST_TYPE.success, data.message)
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setConfirmationShow(false)
    })
  }


  return (
    <>
      <div className='mainContent'>
        <div className='settingPage'>
          <div className='companyOptions'>
            <button onClick={() => settingTab('account')}>Personal Profile</button>
            <button onClick={() => settingTab('profile')}>Company Profile</button>
            <button onClick={() => settingTab('finance')}>Finances</button>
            {/* <button onClick={() => settingTab('users')}>Users</button> 
            <button onClick={() => settingTab('custom')}>Customization</button> */}
            <button onClick={() => settingTab('agreement')}>Onboarding</button>
            <button className='activeBtn' onClick={() => settingTab('tours')}>Tours</button>
          </div>
          <div className='companyProfile'>
            <div className='profileHeading'>
              <h6>Tours</h6>
              <p>Customize your Tours settings.</p>
            </div>
            <div className='profileSave'>
              <button className='cancel'>Cancel</button>
              <button className='save' onClick={() => setConfirmationShow(true)}>Save</button>
            </div>
          </div>
          <div className="resourceDescription mt-4">
            <div className="generateInvoice">
              <h5 className='mb-0'>Add custom instructions to the Tour Confirmation email?</h5>
              <div className="authToggle mt-0">
                {agreement === true ?
                  <label className="switch">
                    <input type="checkbox" onClick={agreementClick} defaultChecked />
                    <span className="slider round"></span>
                  </label> :
                  <label className="switch">
                    <input type="checkbox" onClick={agreementClick} />
                    <span className="slider round"></span>
                  </label>}
              </div>
            </div>
            {agreement === true ? <ReactQuill
              theme='snow'
              modules={modules}
              formats={formats}
              placeholder='Enter a description...'
              onChange={handleProcedureContentChange}
              value={content}
            /> : ""}

          </div>
        </div>
      </div>
      <ConfirmationModal
        ConfirmationShow={confirmationShow}
        afterConfirmationApi={tourAdd}
        handleConfirmationClose={() => setConfirmationShow(false)}
      />
    </>
  )
}

export default Tours