import React, { useEffect, useState } from 'react';
import { singleProfile } from '../../api/settings';
import { DESKIE_API as API } from '../../config';
import logo from "../../Assets/Images/logo/logo.svg";
import "./PublicAssets.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { getSpacesList, singleSpaces } from '../../api/spaces';
import spaceAvatar from "../../Assets/Images/icon/spaceAvatar.png";
import spaceIcon from "../../Assets/Images/icon/spaceAvatar.png";
import { Link } from 'react-router-dom';

const PublicAssets = () => {
    const [profile, setProfile] = useState<any>();
    const [assetView, setAssetView] = useState(true);
    const [spaces, setSpaces] = useState([]);
    const [spacesDetails, setSpacesDetails] = useState<any>({});

    const getSingleProfile = async () => {
        try {
            const { data } = await singleProfile();
            setProfile(data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getSingleProfile();
    }, []);

    useEffect(() => {
        getSpacesList(100, 1, "").then((data) => {
            setSpaces(data && data.spaces);
        }).catch((err) => {
            console.log(err);
        })

    }, []);

    const viewAsset = (id: string) => {
        singleSpaces(id).then((data) => {
            setAssetView(false)
            setSpacesDetails(data.data && data.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const filteredSpaces = spaces.filter((space: any) => space.booked_member_id === null);


    return (
        <>
            <section className="signUpSection" style={{ backgroundImage: `linear-gradient(rgba(31, 41, 55, 0.9), rgba(31, 41, 55, 0.94)), url(${profile && `${API}/${encodeURI(profile.background)}`})` }}>
                <div className="signUpBox">
                    <div className="logo">
                        {profile && profile.company_logo_dark ?
                            <img src={`${API}/${profile.company_logo_dark}`} alt="logo" />
                            : <img src={logo} alt="logo" />
                        }
                    </div>
                    <div className="publicAssets">
                        <div className='publicMember'>
                            <p>Available Spaces</p>
                            <Link to="/public/resources">Explore On-Demand Options</Link>
                        </div>
                        {assetView ? <>{filteredSpaces && filteredSpaces.map((asset: any) => <div className="publicAsset">
                            <div className='assetImage'>
                                {asset.space_image ?
                                    <img src={`${API}/${asset.space_image}`} alt="avatar" style={{ objectFit: "cover" }} />
                                    : <img src={spaceAvatar} alt="avatar" />
                                }
                                <div>
                                    <p>{asset.name}</p><span>${asset.rate} / month • {asset.size} sqft</span>
                                </div>
                            </div>
                            <div className='assetBtnInfo'>
                                <div className='deskType'>
                                    {asset.tag === "private" ? <span className='private'>Private Office</span> : ""}
                                    {asset.tag === "dedicated" ? <span className='dedicated'>Dedicated Desk</span> : ""}
                                    {asset.tag === "flex" ? <span className='flex'>Flex</span> : ""}
                                </div>
                                <button onClick={() => viewAsset(asset.id)}>More Info</button>
                            </div>
                        </div>)}</> : <>
                            {spacesDetails && spacesDetails ?
                                <> <div className="topLine">
                                    <div className="memberName">
                                        <button onClick={() => setAssetView(true)} className='backDashboard'><FontAwesomeIcon icon={faArrowLeft} /></button>
                                        <h6> {spacesDetails.name}</h6>
                                    </div>
                                    <div className="editBtn">
                                        <Link to="/public/tours" style={{ padding: "14px 18px" }}>Book a tour</Link>
                                    </div>
                                </div><div className="spacesInfo">
                                        <div className="rightSpacesContent">
                                            <div className="resourceInfo">
                                                <div className="resourceMiniBox">
                                                    <div className="resourceName">
                                                        <h6 className='mb-3'>Name</h6>
                                                        <p className='mb-0'>{spacesDetails.name}</p>
                                                    </div>
                                                    <div className="resourceName" style={{ borderRight: 'none' }}>
                                                        <h6 className='mb-3'>Size</h6>
                                                        <p className='mb-0'>{spacesDetails.size}sqft</p>
                                                    </div>
                                                </div>
                                                <div className="resourceMiniBox">
                                                    <div className="resourceRate">
                                                        <h6 className='mb-3'>Rate <span style={{ fontWeight: '400' }}>(Members)</span></h6>
                                                        <p className='mb-0'>${spacesDetails.rate} / mo</p>
                                                    </div>
                                                    <div className="resourceName border-bottom-0" style={{ borderRight: 'none', borderLeft: 'none' }}>
                                                        <h6 className='mb-3'>Type</h6>
                                                        <span className='deskType'>
                                                            {spacesDetails.tag === "private" ? <span className='private'>Private Office</span> : ""}
                                                            {spacesDetails.tag === "dedicated" ? <span className='dedicated'>Dedicated Desk</span> : ""}
                                                            {spacesDetails.tag === "flex" ? <span className='flex'>Flex</span> : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='spacesDescription mt-4'>
                                                <div className="spacesNotes">
                                                    <h6 className='mb-3'>Notes</h6>
                                                    <p className='mb-0'>{spacesDetails.notes ? spacesDetails.notes : "no notes"}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="leftSpacesImage">
                                            {spacesDetails.space_image ?
                                                <img src={`${API}/${spacesDetails.space_image}`} alt="avatar" style={{ width: "100%", maxHeight: "350px", objectFit: "cover" }} />
                                                : <img src={spaceIcon} width="100px" height="100px" alt="shop" />
                                            }
                                        </div>
                                    </div>
                                </> : ""}
                        </>}
                    </div>
                </div>
            </section>
        </>
    )
}

export default PublicAssets