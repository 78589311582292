import { get, post } from './base-api';

/*
* @des Get the chat message
* @param sender & receiver
* @return
*/

// chat
export const getChatHistory = (body = {}) => {
    return post(`/chatHistory`,body);
}

/*
* @des Get the chatters
* @param sender email
* @return
*/

// chatter list
export const getChatters = (email:string) => {
    return get(`/chatters/${email}`);
};