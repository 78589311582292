import { get, post, put } from "./base-api";

// invoice add
export const invoiceAdd = (body = {}) => {
  return post("/invoiceCreate", body);
};

export const createInvoice = (body = {}) => {
  return post("/createInvoice", body);
};

// invoice list
export const getInvoicesList = (
  limit: number,
  page: number,
  status: string
) => {
  return get(`/invoicesList?limit=${limit}&page=${page}&status=${status}`);
};
// single invoice
export const singleInvoice = (id: string) => {
  return get(`/invoiceSingle/${id}`);
};

// invoice update
export const invoiceUpdate = (body = {}) => {
  return post(`/invoicePayment`, body);
};

export const publicPaymentInvoice = (body = {}) => {
  return post(`/public/payment-invoice`, body);
};

export const getLastInvoice = () => {
  return get(`/getLastInvoice`);
};

export const getPublicInvoiceId = () => {
  return get(`/public/invoice-id`);
};

export const updatePayment = (id: string, body = {}) => {
  return put(`/updatePayment/${id}`, body);
};
// void update
export const paymentVoid = (id: string, body = {}) => {
  return put(`/paymentVoid/${id}`, body);
};
// invoice view
export const invoicesView = (id: string) => {
  return put(`/invoicesView/${id}`);
};

export const invoicesResource = (id: string) => {
  return get(`/invoicesResource/${id}`);
};

// invoice view
export const invoiceAmountUpdate = (id: string, body = {}) => {
  return post(`/invoiceAmountUpdate/${id}`, body);
};
// invoiceItems
export const invoiceItems = (id: string) => {
  return get(`/invoiceItems/${id}`);
};

// invoiceMonthly
export const invoiceMonthly = () => {
  return post(`/invoiceMonthly`);
};

// // monthlyDate
// export const monthlyDate = async () => {
//   return get(`/monthlyDate`);
// };
